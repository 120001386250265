/**
*
* ---------------------------------------------------------------------------
*
* Template : Blue - A One-Page HTML Portfolio/Business Template
* Author : Muhammad Morshd
* Author URI : http://morsheds.com
*
* --------------------------------------------------------------------------- 
*
*/

/* =================================== */
/*	Basic Style 
/* =================================== */

body {
    background-color: #fff;
    font-family: 'Open Sans', sans-serif;
    line-height: 24px;
    font-size: 16px;
    color: #818181;
}

figure, p, address {
    margin: 0;
}

p {
    line-height: 25px;
}

iframe {
    border: 0;
}

a {
    -webkit-transition: all 0.3s ease-out 0s;
       -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
         -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;
}

a, a:hover, a:focus, .btn:focus {
    text-decoration: none;
    outline: none;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: Arial;
	
	font-style: normal;
	font-variant: normal;
	font-weight: bold;
    color: #d22f2d;
    
}

main > section {
    padding: 70px 0;
}

.btn {
    border-radius: 0;
    border: 0;
    position: relative;
    text-transform: uppercase;
}

.btn-blue {
    background-color: rgba(210,47,45, 1);
    box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.15) inset;
    padding: 15px 55px;
    color: #fff;
}

.btn-border {
    border: 2px solid #fff;
    color: #fff;
    padding: 12px 35px;
}

.bg-blue {
    background-color: #d22f2d;
}

/* Sweep To Right */
.btn-effect {
	vertical-align: middle;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	position: relative;
	display: inline-block;

	-webkit-transform: translateZ(0);
	   -moz-transform: translateZ(0);
		-ms-transform: translateZ(0);
		 -o-transform: translateZ(0);
			transform: translateZ(0);
		  
	-webkit-backface-visibility: hidden;
	   -moz-backface-visibility: hidden;
			backface-visibility: hidden;
		  
	-webkit-transition-property: color;
	   -moz-transition-property: color;
		-ms-transition-property: color;
			transition-property: color;
		  
	-webkit-transition-duration: 0.3s;
	   -moz-transition-duration: 0.3s;
		-ms-transition-duration: 0.3s;
			transition-duration: 0.3s;
		  
	-moz-osx-font-smoothing: grayscale;
}

.btn-effect:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	
	-webkit-transform: scaleX(0);
	   -moz-transform: scaleX(0);
		-ms-transform: scaleX(0);
			transform: scaleX(0);
			
	-webkit-transform-origin: 0 50%;
	   -moz-transform-origin: 0 50%;
		-ms-transform-origin: 0 50%;
			transform-origin: 0 50%;
			
	-webkit-transition-property: transform;
	   -moz-transition-property: transform;
		-ms-transition-property: transform;
			transition-property: transform;
			
	-webkit-transition-duration: 0.3s;
	   -moz-transition-duration: 0.3s;
		-ms-transition-duration: 0.3s;
			transition-duration: 0.3s;
			
	-webkit-transition-timing-function: ease-out;
	   -moz-transition-timing-function: ease-out;
		-ms-transition-timing-function: ease-out;
			transition-timing-function: ease-out;
}

.btn-effect:hover, .btn-effect:focus, .btn-effect:active {
	color: #d22f2d;
}

.btn-effect:hover:before, .btn-effect:focus:before, .btn-effect:active:before {
	-webkit-transform: scaleX(1);
	   -moz-transform: scaleX(1);
	    -ms-transform: scaleX(1);
			transform: scaleX(1);
}

.sec-title {
    margin-bottom: 50px;
}

.sec-title.white {
    color: #fff;
}

.sec-title h2 {
    font-size: 36px;
    margin: 0 0 30px;
    padding-bottom: 30px;
    position: relative;
    text-transform: uppercase;

    
}

.sec-title.white h2 {
    color: #fff;
}

.sec-title h2:before {
    border-bottom: 4px solid #fdfc4a;
    content: "";
    display: block;
    left: 0;
    bottom: 10px;
    position: absolute;
    width: 100%;
}

.sec-title.white h2:before {
    border-bottom: 1px solid #fff;
}

.parallax {
    background-attachment: fixed;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
}


/**
/*	Preloader
/* ==========================================*/

#preloader {
    background-color: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

/*Battery*/
.loder-box {
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 1px;
  height: 100px;
  left: 50%;
  margin-left: -64px;
  margin-top: -50px;
  position: absolute;
  top: 50%;
  width: 128px;
}

.battery{
    width: 60px;
    height: 25px;
    top: 35%;
    border: 1px #2E2E2E solid;
    border-radius: 2px;
    position: relative;
    -webkit-animation: charge 5s linear infinite;
       -moz-animation: charge 5s linear infinite;
            animation: charge 5s linear infinite;
    margin: 0 auto;
}

.battery:after {
  background-color: #2E2E2E;
  border-radius: 0 1px 1px 0;
  content: "";
  height: 10px;
  position: absolute;
  right: -5px;
  top: 7px;
  width: 3px;
}

@-webkit-keyframes charge{
    0%{box-shadow: inset 0px 0px 0px #2E2E2E;}
    100%{box-shadow: inset 60px 0px 0px #2E2E2E;}
}

@-moz-keyframes charge{
    0%{box-shadow: inset 0px 0px 0px #2E2E2E;}
    100%{box-shadow: inset 60px 0px 0px #2E2E2E;}
}

@keyframes charge{
    0%{box-shadow: inset 0px 0px 0px #2E2E2E;}
    100%{box-shadow: inset 60px 0px 0px #2E2E2E;}
}





/**
/*	Header
/* ==========================================*/

#navigation {
    -webkit-transition: all 0.8s ease 0s;
       -moz-transition: all 0.8s ease 0s;
        -ms-transition: all 0.8s ease 0s;
         -o-transition: all 0.8s ease 0s;
            transition: all 0.8s ease 0s;

    background-color: rgba(255,255,255,.8);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    padding: 5px 0;
}

#navigation.animated-header {
    padding: 20px 0;
}

h1.navbar-brand {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase;
}

.navbar-inverse .navbar-nav > li > a {
    color: #000;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
}

.navbar-inverse .navbar-nav > li > a:hover {
    color: #fff;

}
.navbar-inverse .navbar-nav > li > a:focus {
  text-decoration: none;
  background-color: #d22f2d;

}
.navbar-inverse .nav > li > a:hover {
  text-decoration: none;
  background-color: #d22f2d;
}
.navbar-inverse .navbar-nav > li > a {
  border-radius: 4px;
  color:#000;
}
.navbar-inverse .navbar-nav > li + li {
  margin-left: 1px;

}

@media (min-width: 1020px) and (max-width: 1150px) {
    .navbar-inverse .navbar-nav > li > a {
        color: #000;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: bold;
    }
}
@media (min-width: 768px) and (max-width: 1119px) {
    .navbar-inverse .navbar-nav > li > a {
        font-size: 11px;

    }
}




/*=================================================================
	Home Slider
==================================================================*/


#home-slider {
    position: relative;
    padding: 0;
}

.sl-slider-wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.sl-slider {
    position: absolute;
    top: 0;
    left: 0;
}

/* Slide wrapper and slides */

.sl-slide,
.sl-slides-wrapper,
.sl-slide-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
} 

.sl-slide {
    z-index: 1;
}

.slide-caption {
    color: #fff;
    display: table;
    height: 100%;
    left: 0;
    min-height: 100%;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 11;
}

.slide-caption .caption-content {
    vertical-align: middle;
    display: table-cell;
}

.caption-content h2 {
    color: #fff;
    font-size: 52px;
    font-weight: 400;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.caption-content > span {
    display: block;
    font-size: 24px;
    margin-bottom: 45px;
    text-transform: capitalize;
}

.caption-content p {
    font-size: 29px;
    margin-bottom: 65px;
}

/* The duplicate parts/slices */

.sl-content-slice {
    overflow: hidden;
    position: absolute;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    background: #fff;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity : 1;
}

/* Horizontal slice */

.sl-slide-horizontal .sl-content-slice {
    width: 100%;
    height: 50%;
    left: -200px;
    -webkit-transform: translateY(0%) scale(1);
    -moz-transform: translateY(0%) scale(1);
    -o-transform: translateY(0%) scale(1);
    -ms-transform: translateY(0%) scale(1);
    transform: translateY(0%) scale(1);
}

.sl-slide-horizontal .sl-content-slice:first-child {
    top: -200px;
    padding: 200px 200px 0px 200px;
}

.sl-slide-horizontal .sl-content-slice:nth-child(2) {
    top: 50%;
    padding: 0px 200px 200px 200px;
}

/* Vertical slice */

.sl-slide-vertical .sl-content-slice {
    width: 50%;
    height: 100%;
    top: -200px;
    -webkit-transform: translateX(0%) scale(1);
    -moz-transform: translateX(0%) scale(1);
    -o-transform: translateX(0%) scale(1);
    -ms-transform: translateX(0%) scale(1);
    transform: translateX(0%) scale(1);
}

.sl-slide-vertical .sl-content-slice:first-child {
    left: -200px;
    padding: 200px 0px 200px 200px;
}

.sl-slide-vertical .sl-content-slice:nth-child(2) {
    left: 50%;
    padding: 200px 200px 200px 0px;
}

/* Content wrapper */
/* Width and height is set dynamically */
.sl-content-wrapper {
    position: absolute;
}

.sl-content {
    width: 100%;
    height: 100%;
}

/* Default styles for background colors

.btn-effect:after {
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    content: "";
    position: absolute;
    z-index: -1;

    -webkit-transition: all 0.3s ease-out 0s;
       -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
         -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;
}

.btn-effect:hover,
.btn-effect:active {
    color: #0e83cd;
}

.btn-effect:hover:after,
.btn-effect:active:after {
    width: 100%;
} */


/* Project laughtbox setup */

.fancybox-item.fancybox-close {
    background: url("../img/icons/close.png") no-repeat scroll 0 0 transparent;
    height: 50px;
    right: 0;
    top: 0;
    width: 50px;
}

.fancybox-next span {
    background: url("../img/right.png") no-repeat scroll center center #f4811e;
    height: 50px;
    width: 50px;
    right: 0;
}

.fancybox-prev span {
    background: url("../img/left.png") no-repeat scroll center center #f4811e;
    height: 50px;
    width: 50px;
    left: 0;
}

/*=================================================================
	About
==================================================================*/

#about {
    background-color: #d22f2d;
    color: #fff;
}

#about h3 {
    color: #fff;
    margin: 0 0 35px;
    text-transform: uppercase;
}

#about .welcome-block h3 {
    margin: 0 0 65px;
}

#about .welcome-block img {
    border: 3px solid #fff;
    border-radius: 50%;
    margin-right: 35px;
    max-width: 100px;
}

#about .message-body {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 70px;
}

.owl-buttons {
    border: 2px solid #fff;
    display: inline-block;
    float: right;
    padding: 1px 8px 5px;
}

.owl-buttons > div {
    display: inline-block;
    padding: 0 5px;
}

.welcome-message {
    padding: 0 50px;
}

.welcome-message img {
    display: block;
    height: auto;
    max-width: 100px;
    float: left;
}

.welcome-message p {
    font-size: 13px;
    margin-left: 135px;
}

/*=================================================================
	Services
==================================================================*/
.ul-empresa {
    list-style-type:none;
    list-style-image: url('../../img/icon-ul.jpg');
}
.ul-empresa li{
    margin-bottom: 15px;
}
.ul-produto {
    list-style-type:none;
    list-style-image: url('../../img/icon-ul-produto.jpg');
}
.ul-produto li{
    margin-bottom: 15px;
    text-align: left;
}
.service-icon {
    border: 3px solid transparent;
    display: inline-block;
    height: 85px;
    width: 85px;
    line-height: 101px;

    -webkit-transform: rotate(47deg);
       -moz-transform: rotate(47deg);
        -ms-transform: rotate(47deg);
         -o-transform: rotate(47deg);
            transform: rotate(47deg);

    -webkit-transition: all 0.3s ease 0s;
       -moz-transition: all 0.3s ease 0s;
        -ms-transition: all 0.3s ease 0s;
         -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
}

.service-item:hover .service-icon {
    border: 3px solid #009ee3;
    margin-bottom: 20px;
    color: #009ee3;
}

.service-icon i {
    -webkit-transform: rotate(-48deg);
       -moz-transform: rotate(-48deg);
        -ms-transform: rotate(-48deg);
         -o-transform: rotate(-48deg);
            transform: rotate(-48deg);
}

.service-item h3 {
    font-size: 20px;
    text-transform: uppercase;
}

/*=================================================================
	Portfolio
==================================================================*/

.project-wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
}

.project-wrapper li {
    display: inline-block;
}

.portfolio-item {
    cursor: pointer;
    margin: 0 1% 1% 0;
    overflow: hidden;
    position: relative;
    width: 32%;
}

figcaption.mask {
    background-color: rgba(54, 55, 50, 0.79);
    bottom: -126px;
    color: #fff;
    padding: 25px;
    position: absolute;
    width: 100%;
    text-align: left;
    
    -webkit-transition: all 0.4s ease 0s;
       -moz-transition: all 0.4s ease 0s;
        -ms-transition: all 0.4s ease 0s;
         -o-transition: all 0.4s ease 0s;
            transition: all 0.4s ease 0s;
}

.portfolio-item:hover figcaption.mask {
    bottom: 0;
}

figcaption.mask h3 {
    margin: 0;
    color: #fff;
}

ul.external {
    list-style: outside none none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: -47px;
    
    -webkit-transition: all 0.4s ease 0s;
       -moz-transition: all 0.4s ease 0s;
        -ms-transition: all 0.4s ease 0s;
         -o-transition: all 0.4s ease 0s;
            transition: all 0.4s ease 0s;
}

ul.external li {
    display: inline-block;
}

ul.external li a {
    background-color: rgba(255, 255, 255, 0.9);
    color: #818181;
    display: block;
    padding: 10px 18px 13px;
    
    -webkit-transition: all 0.5s ease 0s;
       -moz-transition: all 0.5s ease 0s;
        -ms-transition: all 0.5s ease 0s;
         -o-transition: all 0.5s ease 0s;
            transition: all 0.5s ease 0s;
}

ul.external li a:hover {
    background-color: #d22f2d;
    color: #fff;
}

.portfolio-item:hover ul.external {
    top: 0;
}

.fancybox-skin {
    border-radius: 0;
}

.fancybox-title.fancybox-title-inside-wrap {
    padding: 15px;
}

.fancybox-title h3 {
    margin: 0 0 15px;
}

.fancybox-title p {
    color: #818181;
    font-size: 16px;
    line-height: 22px;
}

.fancybox-title-inside-wrap {
    padding-top: 0;
}

/*=================================================================
	Testimonials
==================================================================*/


#testimonials {
    background-image: url(../img/parallax/testimonial.jpg);
    padding: 0;
    color: #fff;
}

#testimonials .sec-title h2:after {
    border-bottom: 1px solid #fff;
}

#testimonials .overlay {
    background-color: rgba(15,155,224, 0.78);
    padding: 70px 0 40px;
}

.testimonial-item {
    margin: 0 auto;
    padding-bottom: 50px;
    width: 64%;
}

.testimonial-item img {
    border: 3px solid #fff;
    border-radius: 50%;
    display: inline-block;
    height: auto;
    max-width: 100px;
}

.testimonial-item > div {
    line-height: 30px;
    position: relative;
}

.testimonial-item > div:before {
    background-image: url("../img/icons/quotes.png");
    background-repeat: no-repeat;
    bottom: 127px;
    height: 33px;
    left: -35px;
    position: absolute;
    width: 45px;
}

.testimonial-item > div:after {
    background-image: url("../img/icons/quotes.png");
    background-position: -58px 0;
    background-repeat: no-repeat;
    bottom: -50px;
    height: 33px;
    position: absolute;
    right: 0;
    width: 45px;
}

.testimonial-item > div > span {
    display: inline-block;
    font-weight: 700;
    margin: 40px 0 30px;
    text-transform: uppercase;
}

#testimonials .owl-controls.clickable {
    margin-top: 20px;
    text-align: center;
}

#testimonials .owl-buttons {
    border: 2px solid #fff;
    float: inherit;
}

#testimonials .owl-prev:hover,
#testimonials .owl-next:hover {
    color: #fff;
}

.price-table {
    border: 1px solid #e3e3e3;
}

.price-table.featured {
    -webkit-box-shadow: 0 4px 5px rgba(0,0,0,0.19);
       -moz-box-shadow: 0 4px 5px rgba(0,0,0,0.19);
            box-shadow: 0 4px 5px rgba(0,0,0,0.19);
}

.price-table > span {
    color: #252525;
    display: block;
    font-size: 24px;
    padding: 30px 0;
    text-transform: uppercase;
}

.price-table .value {
    background-color: #f8f8f8;
    color: #727272;
    padding: 20px 0;

    -webkit-transition: all 0.7s ease 0s;
       -moz-transition: all 0.7s ease 0s;
        -ms-transition: all 0.7s ease 0s;
         -o-transition: all 0.7s ease 0s;
            transition: all 0.7s ease 0s;
}

.price-table.featured .value {
    background-color: #d22f2d;
    color: #fff;
}

.price-table .value span {
    display: inline-block;
}

.price-table .value span:first-child {
    font-size: 32px;
    line-height: 32px;
}

.price-table .value span:nth-child(2) {
    font-size: 65px;
    line-height: 65px;
    margin-bottom: 25px;
}

.price-table .value span:last-child {
    font-size: 16px;
}

.price-table ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
}

.price-table ul li {
    border-top: 1px solid #e3e3e3;
    display: block;
    padding: 25px 0;

    -webkit-transition: all 0.7s ease 0s;
       -moz-transition: all 0.7s ease 0s;
        -ms-transition: all 0.7s ease 0s;
         -o-transition: all 0.7s ease 0s;
            transition: all 0.7s ease 0s;
}

.price-table ul li a {
    display: block;
    text-transform: uppercase;
}

.price-table.featured ul li:last-child,
.price-table ul li:last-child:hover {
    background-color: #d22f2d;
}

.price-table.featured ul li:last-child a,
.price-table ul li:last-child:hover a {
    color: #fff;
}


/*=================================================================
	Price
==================================================================*/

#social {
    background-image: url(../img/slider/banner-icons.jpg);

    padding: 0;
}

#social .overlay {
    background: url("../img/slide_bg.png") repeat scroll 0 0 transparent;
    padding: 100px 0 120px;
}

.social-button {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
}

/*.social-button li {
    display: inline-block;
}

.social-button li:nth-child(2) {
    margin: 0 75px;
}*/

.social-button div a {
    border: 2px solid rgba(245, 130, 31, .5);
    border-radius: 50%;
    color: #d22f2d;
    display: block;
    height: 90px;
    line-height: 96px;
    width: 90px;
  
    
    -webkit-transition: all 0.2s ease 0s;
       -moz-transition: all 0.2s ease 0s;
        -ms-transition: all 0.2s ease 0s;
         -o-transition: all 0.2s ease 0s;
            transition: all 0.2s ease 0s;
}

.social-button div a:hover {
    color: #fff;
    /*border: 2px solid #fff;*/
}


/*=================================================================
	Contact
==================================================================*/

.input-field {
    margin-bottom: 10px;
}

.form-control {
    border: 1px solid #ececec;
    border-radius: 0;
    box-shadow: none;
    color: #818181;
    font-size: 16px;
    height: 50px;
}

textarea.form-control {
    width: 100%;
    height: 165px;
}

#submit:hover {
  color: #fff;
}

#submit:before {
  background-color: #2e2e2e;
}

#submit.btn-effect:after {
  background: #2E2E2E;
}

.contact-details h3 {
    border-bottom: 1px solid #dedede;
    font-weight: 700;
    margin-bottom: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
}

.contact-details p {
    line-height: 30px;
}

.contact-details p i {
    margin-right: 10px;
}

.contact-details span {
    display: block;
    margin-left: 24px;
}


/*============================================================
	Google Maps
==============================================================*/

#google-map {
    padding: 0;
}

#map-canvas {
    width: 100%;
    height: 300px;
}


/*============================================================
	Footer
==============================================================*/

#footer {
    background-color: #2E2E2E;
    padding: 70px 0;
    color: #fff;
}

.footer-content {
    width: 390px;
    margin: 0 auto;
}

.footer-content > div {
    margin-bottom: 40px;
}

.footer-content > div > p:first-child {
    margin-bottom: 15px;
    text-transform: uppercase;
}

.subscribe-form {
    position: relative;
}

.subscribe.form-control {
    background-color: transparent;
    border: 1px solid #7f7f7f;
}

.subscribe.form-control:focus {
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
}

.submit-icon {
    background-color: #7f7f7f;
    border: 0 none;
    border-radius: 0;
    color: #c1c1c1;
    padding: 13px 20px;
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 0;
}

.submit-icon .fa-paper-plane {
    position: relative;
    top: 0;
    
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
        -ms-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);

    -webkit-transition: all 0.3s ease 0.2s;
       -moz-transition: all 0.3s ease 0.2s;
        -ms-transition: all 0.3s ease 0.2s;
         -o-transition: all 0.3s ease 0.2s;
            transition: all 0.3s ease 0.2s;
}

.submit-icon:hover .fa-paper-plane {
    position: relative;
    top: -37px;
    
    -webkit-transform: translateX(30px);
       -moz-transform: translateX(30px);
        -ms-transform: translateX(30px);
         -o-transform: translateX(30px);
            transform: translateX(30px);
}

.footer-content .footer-social {
    margin: 40px 0 35px;
}

.footer-social ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
    text-align: center;
}

.footer-social ul li {
    display: inline-block;
    margin: 0 10px;
}

.footer-social ul li a {
    color: #7f7f7f;
    display: block;
    
    -webkit-transition: all 0.2s ease 0s;
       -moz-transition: all 0.2s ease 0s;
        -ms-transition: all 0.2s ease 0s;
         -o-transition: all 0.2s ease 0s;
            transition: all 0.2s ease 0s;
}

.footer-social ul li a:hover {
    color: #d22f2d;
}

.footer-content > p {
    color: #ababab;
    font-size: 12px;
}



/*============================================================ 
	Responsive Styles
 ============================================================*/

/*============================================================
	For Small Desktop
==============================================================*/

@media (min-width: 980px) and (max-width: 1150px) {

/*about*/
    #about .welcome-block img {
        margin-bottom: 30px;
    }

/* portfolio */
    figcaption.mask {
        bottom: -151px;
    }

    .testimonial-item > div:after {
        bottom: -40px;
    }
}


/*============================================================
	Tablet (Portrait) Design for a width of 768px
==============================================================*/

@media (min-width: 768px) and (max-width: 979px) {

/* home slider  */
    .caption-content h2 {
        font-size: 40px;
    }

    .caption-content p {
        font-size: 25px;
    }

    .caption-content strong {
        font-size: 45px;
    }

/* about */
    .recent-works {
        margin-bottom: 50px;
    }

    .service-item {
        margin-bottom: 50px;
    }

/* portfolio */
    .portfolio-item {
        width: 48%;
    }

/* testimonial */
    .testimonial-item {
        width: 100%;
    }

    .testimonial-item {
        width: 80%;
    }

    .testimonial-item > div:after {
        bottom: -35px;
    }

/* price */
    .price-table {
        margin-bottom: 50px;
    }

/* contact form */
    .contact-form {
        margin-bottom: 50px;
    }
}


/*============================================================
	Mobile (Portrait) Design for a width of 320px
==============================================================*/

@media only screen and (max-width: 767px) {

    body {
        font-size: 14px;
    }

    .parallax {
        background-position: centet tip !important;
    }

    .sec-title h2 {
        font-size: 25px;
    }

    .sec-title h2:after {
        left: 30%;
    }

/*navigation*/
    .navbar-inverse .navbar-toggle {
        border-color: #d22f2d;
        color:#d22f2d
    }
    .navbar-inverse .navbar-toggle .icon-bar {
        border-color: #d22f2d;
        color:#d22f2d
    }    

    .navbar-inverse .navbar-toggle:hover,
    .navbar-inverse .navbar-toggle:focus {
        background-color: transparent;
    }

/* slider */
    .caption-content h2 {
        font-size: 18px;
    }

    .caption-content > span {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .caption-content p {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .caption-content strong {
        font-size: 22px;
    }

/* about */
    .recent-works {
        margin-bottom: 85px;
    }

    #about h3 {
        font-size: 18px;
        margin: 0 0 35px !important;
    }

    #about .owl-buttons {
        margin-top: 20px;
    }

    #about .message-body {
        margin-bottom: 45px;
    }

    #about .welcome-block img {
        margin: 0 25px 15px 0;
    }

/* service */
    .service-item {
        width: 100%;
    }

/*portfolio*/
    .portfolio-item {
        margin: 0 auto 10px;
        font-size: 14px;
        width: 280px;
    }

    figcaption.mask {
        bottom: -107px;
        color: #fff;
        padding: 15px;
    }

/* lightbox */
    .fancybox-title h3 {
        font-size: 20px;
    }

    .fancybox-title p {
        font-size: 14px;
    }

/* testimonial */
    .testimonial-item {
        width: 95%;
    }

    .testimonial-item > div:before,
    .testimonial-item > div:after {
        background-image: none;
    }

    .testimonial-item > div > span {
        margin: 30px 0 20px;
    }

/* price */
    .price-table {
        margin-bottom: 50px;
    }

/* follow us */
   /* .social-button li:nth-child(2) {
        margin: 0 25px;
    }

    .social-button li a {
        height: 65px;
        line-height: 71px;
        width: 65px;
    }
*/
/* contact form */
    .contact-form {
        margin-bottom: 50px;
    }

/* footer */
    .footer-content {
        width: 100%;
    }

    .footer-social ul li {
        margin: 0 7px;
    }
}


/*============================================================
	Mobile (Landscape) Design for a width of 480px
==============================================================*/

@media only screen and (min-width: 480px) and (max-width: 767px) {

    .sec-title h2:after {
        left: 38%;
    }

/* home slider */
    .caption-content h2 {
        font-size: 35px;
    }

    .caption-content p {
        font-size: 25px;
    }

    .caption-content strong {
        font-size: 35px;
    }

/*about*/

    #about .welcome-block h3 {
        margin: 0 0 35px;
    }

/* service */

    .service-item {
        margin: 0 auto 50px;
        width: 55%;
    }

/* portfolio */
    .portfolio-item {
        width: 48%;
    }

    figcaption.mask {
        bottom: -132px;
    }

/* testimonial */
    .testimonial-item {
        width: 80%;
    }

    .testimonial-item > div:before {
        bottom: 160px;
    }

/* social */
  /*  .social-button li:nth-child(2) {
        margin: 0 50px;
    }

    .social-button li a {
        height: 90px;
        line-height: 96px;
        width: 90px;
    }*/

/* price */
    .price-table {
        margin-bottom: 50px;
    }

/* contact form */
    .contact-form {
        margin-bottom: 50px;
    }

/* footer */
    .footer-content {
        width: 380px;
    }
}
.nav-pills > li {
    float:none;
    display:inline-block;
    *display:inline; /* ie7 fix */
     zoom:1; /* hasLayout ie7 trigger */
}

.nav-pills {
    text-align:center;
    
}
.nav-pills > li > a {
  
    font-size: 15px;
    text-transform: uppercase;
}

.nav-pills > li > a:hover {
 

}
.nav-pills > li > a:hover,
.nav-pills > li > a:focus{
  text-decoration: none;
  background-color: #d22f2d;
  color:#fff;
}
.nav-pills > li > a {
  border-radius: 4px;
  color:#acacac;
  font-size: 12px;
}
.nav-pills > li + li {
  margin-left: 1px;

}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: #d22f2d;
  color:#fff;
}

.list-atuacao{
    font-size:20px;
}
.list-atuacao div{
    padding-bottom: 15px;
}